import { createWebHistory, createWebHashHistory, createMemoryHistory, createRouter } from "vue-router";
// lazy-loaded
const Home = () => import("./components/Home.vue")
// const Shop = () => import("./components/Shop.vue")
// const AboutUs = () => import("./components/AboutUs.vue")
// const Services = () => import("./components/Services.vue")
// const Blog = () => import("./components/Blog.vue")
// const ContactUs = () => import("./components/ContactUs.vue")


const EntryBasic = () => import("./components/services/EntryBasic.vue")
const EntryGame = () => import("./components/services/EntryGame.vue")
const AppInventor = () => import("./components/services/AppInventor.vue")
const EntryDog = () => import("./components/services/EntryDog.vue")

const HamsterBot = () => import("./components/services/HamsterBot.vue")
const AsomBot = () => import("./components/services/AsomBot.vue")
const HaloCode = () => import("./components/services/HaloCode.vue")

const TinkerRoom = () => import("./components/services/TinkerRoom.vue")
const TinkerObject = () => import("./components/services/TinkerObject.vue")
const TinkerSimLab = () => import("./components/services/TinkerSimLab.vue")

const JapanessBasic = () => import("./components/services/JapanessBasic.vue")
const JapanessMedium = () => import("./components/services/JapanessMedium.vue")


const routes = [
  {
    path: "/",
    component: Home,
  },
 
  {
    path: "/entry_b",
    component: EntryBasic,
  },
  {
    path: "/entry_g",
    component: EntryGame,
  },
  {
    path: "/inventor",
    component: AppInventor,
  },
  {
    path: "/entry_dog",
    component: EntryDog,
  },
  {
    path: "/hBot",
    component: HamsterBot,
  },
  {
    path: "/aBot",
    component: AsomBot,
  },
  {
    path: "/hCode",
    component: HaloCode,
  },

  {
    path: "/tinker_r",
    component: TinkerRoom,
  },
  {
    path: "/tinker_o",
    component: TinkerObject,
  },
  {
    path: "/tinker_s",
    component: TinkerSimLab,
  },

  {
    path: "/jp_b",
    component: JapanessBasic,
  },
  {
    path: "/jp_m",
    component: JapanessMedium,
  },


];
// const scrollBehavior = function (to, from, savedPosition) {
//   if (to.hash) {
//     return {
//       selector: to.hash,
//       offset: { x: 0, y: -500 }
//     }
//   }
//   return { x: 0, y: 0 };
// }

// const router = new VueRouter({
//   mode: "history",
//   routes,
// });

const router = createRouter({
  // history: createWebHistory(process.env.BASE_URL),
  // history: createWebHashHistory(),
  history: createWebHistory(),
  // history: createMemoryHistory(),
  // scrollBehavior,
  routes,
});

// router.beforeEach((to, from, next) => {
//   const publicPages = ['/login', '/register', '/home'];
//   const authRequired = !publicPages.includes(to.path);
//   const loggedIn = localStorage.getItem('user');

//   // trying to access a restricted page + not logged in
//   // redirect to login page
//   if (authRequired && !loggedIn) {
//     next('/login');
//   } else {
//     next();
//   }
// });

export default router;